<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
    :loading="packs_loading"
  >
    <template v-if="$can('read', 'catalog')" slot="actions">
      <div slot="actions">
        <div class="field has-addons">
          <div class="is-flex mr-2">
            <b-button
              v-if="$device.mobile"
              size="is-small"
              rounded
              @click="showMobile()"
            >
              <b-icon icon="magnify" size="is-small" />
            </b-button>
            <b-input
              ref="search"
              v-model="headers[1].input"
              :class="{ expand: searchFocused, 'hide-search': $device.mobile }"
              :placeholder="$t('search')"
              icon="magnify"
              rounded
              :size="$device.mobile ? 'is-small' : ''"
              @input="getPacks()"
              @blur="hideMobile()"
            />
          </div>
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            scrollable
            max-height="400px"
            append-to-body
          >
            <div slot="trigger">
              <b-button
                icon-left="dots-vertical"
                :size="$device.mobile ? 'is-small' : ''"
                type="is-default"
                rounded
              />
            </div>

            <b-dropdown-item
              v-if="$can('create', 'catalog')"
              aria-role="listitem"
              has-link
            >
              <router-link to="/catalog/packs/create">
                <b-icon icon="plus" size="is-small" />
                {{ $t("create_pack") }}
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </template>
    <Card v-if="$can('read', 'catalog')" content-class="c-table">
      <div class="header-container">
        <div />
        <b-field position="is-right">
          <p v-for="item in status" :key="item.value" class="control">
            <b-button
              size="is-small"
              :type="item.value == selected_status ? 'is-primary' : ''"
              @click="
                () => {
                  selected_status = item.value;
                  getPacks();
                }
              "
            >
              {{ item.text }}
            </b-button>
          </p>
        </b-field>
      </div>
      <hr class="my-0" />
      <b-table
        :data="packs"
        :loading="packs_loading"
        hoverable
        mobile-cards
        striped
        backend-sorting
        sort-multiple
        :sort-multiple-data="sortingPriority"
        sort-multiple-key="shiftKey"
        :total="total"
        @sort="sortPressed"
        @sorting-priority-removed="sortingPriorityRemoved"
      >
        <template v-for="head in headers">
          <b-table-column
            :key="head.value"
            :field="head.value"
            :label="head.text"
            :width="head.width"
            :centered="head.centered"
            :sortable="head.sortable"
            :searchable="head.searchable"
            :numeric="head.numeric"
            :sticky="false"
            v-bind="head"
            header-class="sticky-header"
          >
            <template slot="searchable">
              <template v-if="head.filter_type == 'autocomplete'">
                <b-autocomplete-validation
                  v-model="head.input"
                  expanded
                  fit-content
                  class="has-text-weight-normal"
                  width="100%"
                  rounded
                  size="is-small"
                  :data="head.filter_values"
                  :placeholder="head.text"
                  :item-text="head.filter_text"
                  :field="head.filter_id"
                  @change="getPacks()"
                />
              </template>
              <template v-else-if="head.filter_type == 'boolean'">
                <b-select-validation
                  v-model="head.input"
                  rounded
                  expanded
                  size="is-small"
                  hide-details
                  @change="getPacks()"
                >
                  <template>
                    <option :value="true">
                      {{ $t("yes") }}
                    </option>
                    <option :value="false">
                      {{ $t("no") }}
                    </option>
                  </template>
                </b-select-validation>
              </template>
              <template v-else>
                <b-input
                  v-if="head.searchable"
                  v-model="head.input"
                  :placeholder="$root.getFilterPlaceholder(head)"
                  size="is-small"
                  icon-right="filter"
                  rounded
                  icon-right-clickable
                  @icon-right-click="showDrop(head.value)"
                  @input="getPacks"
                />
                <b-dropdown
                  :ref="`filter_drop_${head.value}`"
                  aria-role="list"
                  class="is-flex"
                >
                  <template v-if="head.filter_type == 'numeric'">
                    <b-dropdown-item
                      v-for="filter in filtersData.numeric"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>

                  <template v-else>
                    <b-dropdown-item
                      v-for="filter in filtersData.char"
                      :key="filter.value"
                      aria-role="listitem"
                      @click="changeFilter(head.value, filter.value)"
                    >
                      {{ filter.label }}
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </template>
            </template>

            <template v-if="head.value == 'id'" v-slot="props">
              <router-link
                v-if="$can('read', 'catalog') || $can('update', 'catalog')"
                :to="`/catalog/packs/${props.row.id}`"
              >
                {{ props.row.id }}
              </router-link>
              <span v-else>{{ props.row.id }}</span>
            </template>

            <template v-else-if="head.value == 'discount'" v-slot="props">
              {{ props.row.discount && props.row.discount.formatted }}
            </template>

            <template v-else-if="head.value == 'noindex'" v-slot="props">
              <b-icon
                :icon="props.row.noindex ? 'check' : 'close'"
                custom-size="mdi-18px"
                :type="props.row.noindex ? 'is-success' : 'is-danger'"
              />
            </template>

            <template v-else-if="head.value == 'name'" v-slot="props">
              {{ props.row.name }}
            </template>

            <template v-else-if="head.value == 'actions'" v-slot="props">
              <b-dropdown
                :key="props.row.id"
                aria-role="list"
                position="is-bottom-left"
                append-to-body
              >
                <div slot="trigger">
                  <b-icon
                    icon="dots-vertical"
                    size="is-small"
                    class="is-clickable"
                  />
                </div>

                <b-dropdown-item
                  v-if="$can('read', 'catalog') || $can('update', 'catalog')"
                  aria-role="listitem"
                  has-link
                >
                  <router-link :to="`/catalog/packs/${props.row.id}`">
                    <b-icon icon="eye" size="is-small" />
                    {{ $t("view") }}
                  </router-link>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    selected_status == 'active' && $can('delete', 'catalog')
                  "
                  aria-role="listitem"
                  @click="confirmDeleting(props.row.id)"
                >
                  <b-icon icon="delete" type="is-danger" size="is-small" />
                  {{ $t("delete") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="
                    selected_status == 'inactive' && $can('update', 'catalog')
                  "
                  aria-role="listitem"
                  @click="RestorePacks(props.row.id)"
                >
                  <b-icon icon="refresh" type="is-success" size="is-small" />
                  {{ $t("restore") }}
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-else v-slot="props">
              {{ props.row[head.value] || `-` }}
            </template>
          </b-table-column>
        </template>

        <EmptyBlock slot="empty" icon="account-supervisor" />
      </b-table>
      <Pagination
        :per-page="perPage"
        :per-pages="perPages"
        :total="total"
        :current-page="page"
        @update-per-page="(val) => (perPage = val)"
        @update-current-page="(val) => (page = val)"
      />
    </Card>
  </Page>
</template>

<script>
import debounce from "debounce";

export default {
  data() {
    return {
      searchFocused: false,
      total: 0,
      sortingPriority: [{ field: "id", order: "asc" }],
      singleSorting: {},
      perPages: [50, 100, 200],
      perPage: 50,
      page: 1,
      packs_loading: true,
      in_progress: null,
      packs: [],
      categories: [],
      warehouse: null,
      warehouses: [],
      status: [
        {
          text: this.$tc("active", 2),
          value: "active",
        },
        {
          text: this.$tc("inactive", 2),
          value: "inactive",
        },
      ],
      selected_status: "active",
      headers: [
        {
          text: this.$i18n.t("id"),
          value: "id",
          width: "100",
          sortable: true,
          searchable: true,
          filter_type: "numeric",
          input: "",
          filter: "is",
        },
        {
          text: this.$i18n.tc("name", 1),
          value: "name",
          sortable: true,
          searchable: true,
          input: "",
          filter: "contains",
        },
        {
          text: this.$i18n.tc("category", 1),
          value: "category",
          width: "200",
          sortable: true,
          searchable: true,
          filter_type: "autocomplete",
          filter_text: "label",
          filter_id: "id",
          filter_values: [],
          input: null,
          filter: "is",
        },
        {
          text: this.$i18n.t("noindex"),
          value: "noindex",
          width: "80",
          sortable: true,
          searchable: true,
          filter_type: "boolean",
          input: null,
          filter: "is",
        },
        {
          text: this.$i18n.t("actions"),
          value: "actions",
          sortable: false,
          searchable: false,
          width: "80",
          centered: true,
        },
      ],
    };
  },
  watch: {
    perPage() {
      this.getPacks();
    },
    page() {
      this.getPacks();
    },
  },
  created() {
    this.updateTitle(this.$i18n.tc("pack", 2));
    this.getPacks();
  },
  methods: {
    showMobile() {
      if (this.$device.mobile) {
        this.searchFocused = true;
        this.$refs.search.focus();
      }
    },
    hideMobile() {
      if (this.$device.mobile) {
        this.searchFocused = false;
      }
    },
    sortPressed(field, order, event) {
      if (event["shiftKey"]) {
        let existingPriority = this.sortingPriority.filter(
          (i) => i.field === field
        )[0];
        if (existingPriority) {
          existingPriority.order =
            existingPriority.order === "desc" ? "asc" : "desc";
        } else {
          if (this.singleSorting.field)
            this.sortingPriority.push(this.singleSorting);
          this.sortingPriority.push({ field, order });
        }
        this.singleSorting = {};
      } else {
        this.sortingPriority = []; // [{field, order}]
        this.singleSorting = { field, order };
      }
      this.getPacks();
    },
    changeFilter(field, value) {
      let index = this.headers.findIndex((h) => h.value == field);
      this.headers[index].filter = value;
      if (this.headers[index].input) this.getPacks();
    },
    showDrop(field) {
      this.$refs[`filter_drop_${field}`][0].toggle();
    },
    sortingPriorityRemoved(value) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== value
      );
      this.getPacks();
    },
    confirmDeleting(id) {
      this.$buefy.dialog.confirm({
        message: `<b>${this.$t("are_you_sure")}</b>`,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        type: "is-danger",
        onConfirm: () => this.deletePack(id),
      });
    },
    getPacks: debounce(function () {
      if (this.$can("read", "catalog")) {
        let api_params = `?page=${this.page}&per_page=${this.perPage}`;

        if (this.selected_status !== "active")
          api_params += `&status=${this.selected_status}`;

        if (this.sortingPriority.length > 1) {
          let sortArr = [];
          for (let i = 0; i < this.sortingPriority.length; i++) {
            const sort = this.sortingPriority[i];
            sortArr.push(`${sort.field}:${sort.order}`);
          }
          api_params += `&sorting=${sortArr.join(",")}`;
        } else if (this.sortingPriority.length == 1) {
          api_params += `&sorting=${this.sortingPriority[0].field}:${this.sortingPriority[0].order}`;
        } else if (this.singleSorting.field) {
          api_params += `&sorting=${this.singleSorting.field}:${this.singleSorting.order}`;
        }

        let searchArr = [];
        for (let i = 0; i < this.headers.length; i++) {
          let value = this.headers[i];
          if (
            ["name"].includes(value.value) &&
            value.input &&
            value.input.length != 0 &&
            value.input.length < 3
          )
            return;
          else if (value.input) {
            let obj = {
              id: value.value,
              selected: value.input,
            };
            if (value.filter) obj.operator = value.filter;
            searchArr.push(obj);
          }
        }
        if (searchArr.length)
          api_params += `&filters=${btoa(JSON.stringify(searchArr))}`;

        this.packs_loading = true;

        this.$axios
          .get("packs" + api_params)
          .then((res) => {
            this.packs = res.data.list ? res.data.list : [];
            this.total = res.data.pagination.total;
            this.warehouses = res.data.filters.warehouses
              ? res.data.filters.warehouses
              : [];
            if (!this.warehouse)
              this.warehouse = this.warehouses[0] && this.warehouses[0].id;

            let category_index = this.headers.findIndex(
              (h) => h.value == "category"
            );
            if (this.headers[category_index].filter_values.length == 0) {
              this.headers[category_index].filter_values =
                res.data.filters.categories.packs.reduce((arr, c1) => {
                  let sub_children = c1.children.reduce((arr2, c2) => {
                    return c2.children
                      ? [...arr2, c2, ...c2.children]
                      : [...arr2, c2];
                  }, []);
                  return [...arr, c1, ...sub_children];
                }, []);
            }
          })
          .catch((e) => this.clientError(e))
          .finally(() => (this.packs_loading = false));
      }
    }, 200),
    deletePack(id) {
      this.in_progress = id;
      this.$axios
        .delete("packs/" + id)
        .then(() => {
          this.packs = this.packs.filter((i) => i.id !== id);
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.in_progress = null));
    },
    RestorePacks(id) {
      this.in_progress = id;
      this.$axios
        .patch("packs/" + id + "/restore")
        .then(() => {
          this.packs = this.packs.filter((i) => i.id !== id);
          this.$root.notify(this.$t("successfully_restored"), "is-success");
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.in_progress = null));
    },
  },
};
</script>

<style></style>
